import { type Theme, useMediaQuery, useTheme } from '@chakra-ui/react'

export const useIsMobileWidth = () => {
  const {
    breakpoints: { sm },
  } = useTheme<Theme>()
  const [isMobileWidth] = useMediaQuery(`(max-width: calc(${sm} - 1px))`)

  return { isMobileWidth }
}
